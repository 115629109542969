import FeatureBlock from "./custom/FeatureBlock";
import FeatureBlockData from "./custom/data/FeatureBlockData";

function FeatureSection() {
  return (
    <section className="feature-section">
      <div className="auto-container">
        <div
          className="inner-container clearfix wow fadeInLeft animated"
          data-wow-delay="00ms"
          data-wow-duration="1500ms"
        >
          <div className="feature-container">
            {FeatureBlockData.map((feature, index) => (
              <FeatureBlock
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
export default FeatureSection;
