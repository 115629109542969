import axios from "axios";
import {
  fetchBannersStart,
  getBannersSuccess,
  getBannersError,
} from "../slices/bannersSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchBanners = () => async (dispatch) => {
  dispatch(fetchBannersStart());
  try {
    const res = await axios.get(`${API_URL}/banners`, {
      headers: getAuthHeader(),
    });
    dispatch(getBannersSuccess(res.data.data));
  } catch (err) {
    console.log("Get Banners Error:", err.response);
    dispatch(getBannersError(err.response.data.error));
  }
};
