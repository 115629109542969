const FAQsSkeleton = () => {
  const styles = {
    container: {
      maxWidth: "1140px",
      margin: "50px auto",
      padding: "40px 15px",
    },
    title: {
      height: "24px",
      width: "200px",
      backgroundColor: "#e0e0e0",
      margin: "0 auto 10px",
      borderRadius: "4px",
    },
    heading: {
      height: "40px",
      width: "60%",
      backgroundColor: "#e0e0e0",
      margin: "0 auto 40px",
      borderRadius: "4px",
    },
    columns: {
      display: "flex",
      justifyContent: "space-between",
    },
    column: {
      width: "48%",
    },
    question: {
      height: "24px",
      backgroundColor: "#e0e0e0",
      marginBottom: "10px",
      borderRadius: "4px",
    },
    answer: {
      height: "60px",
      backgroundColor: "#f5f5f5",
      marginBottom: "20px",
      borderRadius: "4px",
    },
  };

  const pulseAnimation = `
    @keyframes pulse {
      0% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.6;
      }
    }
  `;

  const FaqItem = ({ showAnswer }) => (
    <div>
      <div
        style={{ ...styles.question, animation: "pulse 1.5s infinite" }}
      ></div>
      {showAnswer && (
        <div
          style={{ ...styles.answer, animation: "pulse 1.5s infinite" }}
        ></div>
      )}
    </div>
  );

  return (
    <div style={styles.container}>
      <style>{pulseAnimation}</style>
      <div style={{ ...styles.title, animation: "pulse 1.5s infinite" }}></div>
      <div
        style={{ ...styles.heading, animation: "pulse 1.5s infinite" }}
      ></div>
      <div style={styles.columns}>
        <div style={styles.column}>
          <FaqItem showAnswer={true} />
          <FaqItem showAnswer={false} />
          <FaqItem showAnswer={false} />
          <FaqItem showAnswer={true} />
        </div>
        <div style={styles.column}>
          <FaqItem showAnswer={true} />
          <FaqItem showAnswer={false} />
          <FaqItem showAnswer={false} />
          <FaqItem showAnswer={true} />
        </div>
      </div>
    </div>
  );
};

export default FAQsSkeleton;
