import { useState } from "react";
import { useDispatch } from "react-redux";
import { submitContactForm } from "../redux/actions/contact";
import PageTitle from "../components/custom/PageTitle";

function ContactUs() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      // Only allow numeric input for phone
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    // Client-side validation
    if (formData.subject.length > 100) {
      setError("Subject cannot be more than 100 characters");
      setLoading(false);
      return;
    }
    if (formData.message.length > 500) {
      setError("Message cannot be more than 500 characters");
      setLoading(false);
      return;
    }

    const result = await dispatch(submitContactForm(formData));
    setLoading(false);

    if (result.success) {
      setSuccess(result.message);
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    } else {
      setError(result.message);
    }
  };

  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Contact Details"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Contact Us" }]}
      />

      <section className="contact-info-section centred pt_120 pb_90">
        <div className="auto-container">
          <div className="sec-title mb_70">
            <h6>Contact US</h6>
            <h2>Contact Details</h2>
          </div>
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 info-column ">
              <div
                className="info-block-one wow fadeInUp animated "
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <i className="icon-2"></i>
                  </div>
                  <h3>Our Location</h3>
                  <p>
                    20th Street, Sinkor Tubman Boulevard <br />
                    Monrovia, Liberia. <br />
                    P.O.Box 1230
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-column">
              <div
                className="info-block-one wow fadeInUp animated"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <i className="icon-43"></i>
                  </div>
                  <h3>Email Address</h3>
                  <p>
                    <a href="mailto:info@accessbankliberia.com">
                      info@accessbankliberia.com
                    </a>
                    <br />{" "}
                    <a href="mailto:support@accessbankliberia.com">
                      {/* support@accessbankliberia.com */}
                    </a>
                  </p>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 info-column">
              <div
                className="info-block-one wow fadeInUp animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <i className="icon-44"></i>
                  </div>
                  <h3>Phone Number</h3>
                  <p>
                    <a href="tel:+231770019572">+231 770 019 572</a> <br />
                    <a href="tel:+231555395370">+231 555 395 370</a> <br />
                    <a href="tel:5055">Call 5055</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="google-map-section pb_120">
        <div className="auto-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.795895442069!2d-10.774264196492105!3d6.290535796505146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf0a0299304d7471%3A0x88ecaa18e64abbc0!2sAccess%20Bank%20Liberia!5e0!3m2!1sen!2s!4v1723405684807!5m2!1sen!2s"
            width="600"
            height="535"
            frameBorder="0"
            style={{ border: "0", width: "100%" }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps location of Access Bank Liberia"
          ></iframe>
        </div>
      </section>

      <section className="contact-section pt_120 pb_120">
        <div className="auto-container">
          <div className="sec-title centred mb_70">
            <h6>Contact US</h6>
            <h2>Contact Details</h2>
          </div>
          <div className="form-inner">
            <form
              onSubmit={handleSubmit}
              id="contact-form"
              className="default-form"
            >
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    placeholder="Full Name"
                    required
                    maxLength="50"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    maxLength="20"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    placeholder="Subject"
                    maxLength="100"
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Type message"
                    required
                    maxLength="500"
                  ></textarea>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                  <button
                    className="theme-btn btn-one"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send Message"}
                  </button>
                </div>
              </div>
            </form>

            {success && (
              <div className="alert alert-success mt-3">{success}</div>
            )}
            {error && <div className="alert alert-danger mt-3">{error}</div>}
          </div>
        </div>
      </section>
    </div>
  );
}
export default ContactUs;
