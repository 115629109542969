import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: [],
  contact: null,
  loading: false,
  success: false,
  error: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    createContactSuccess: (state, action) => {
      state.contact = action.payload;
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    contactError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    resetContactState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
});

export const { createContactSuccess, contactError, resetContactState } =
  contactSlice.actions;

export default contactSlice.reducer;
