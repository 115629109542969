import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const tariffsSlice = createSlice({
  name: "tariffs",
  initialState,
  reducers: {
    getTariffsSuccess: (state, action) => {
      state.items = action.payload;
      state.status = "succeeded";
    },
    getTariffsError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchTariffsStart: (state) => {
      state.status = "loading";
    },
  },
});

export const { getTariffsSuccess, getTariffsError, fetchTariffsStart } =
  tariffsSlice.actions;
export default tariffsSlice.reducer;
