import PageTitle from "../components/custom/PageTitle";
import DownloadReport from "../components/DownloadReport";

function AnnualReport() {
  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Annual Report"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Annual Report" }]}
      />

      <DownloadReport />
    </div>
  );
}
export default AnnualReport;
