import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobDetails } from "../redux/actions/jobs";
import PageTitle from "../components/custom/PageTitle";

function CareerDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentJob, currentJobStatus, currentJobError } = useSelector(
    (state) => state.jobs
  );

  useEffect(() => {
    dispatch(fetchJobDetails(id));
  }, [dispatch, id]);

  if (currentJobStatus === "loading") {
    return <div>Loading...</div>;
  }

  if (currentJobStatus === "failed") {
    return <div>Error: {currentJobError}</div>;
  }

  if (!currentJob) {
    return <div>Job not found</div>;
  }

  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Career Details"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Career Details" }]}
      />

      <section className="career-details pt_120 pb_120">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-8 col-md-12 col-sm-12 content-side">
              <div className="career-details-content">
                <div className="content-one mb_60">
                  <h2>{currentJob.jobTitle}</h2>
                  <span>{currentJob.department}</span>
                  <p>{currentJob.description}</p>
                </div>
                <div className="content-two mb_60">
                  <h3>What will You be Doing?</h3>
                  <ul className="list-style-one clearfix">
                    {currentJob.responsibilities.map((resp, index) => (
                      <li key={index}>{resp}</li>
                    ))}
                  </ul>
                </div>
                <div className="content-three mb_80">
                  <h3>Skills You'll Need</h3>
                  <ul className="list-style-one clearfix">
                    {currentJob.skills.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))}
                  </ul>
                </div>
                <div className="content-four">
                  <div className="btn-box">
                    <a href="#" className="theme-btn btn-one">
                      Apply For Job
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div className="career-sidebar ml_40">
                <h4>Information</h4>
                <ul className="info-list clearfix">
                  <li>
                    <i className="icon-22"></i>
                    <h6>Salary</h6>
                    <p>{`${currentJob.salary.currency}${currentJob.salary.min} - ${currentJob.salary.currency}${currentJob.salary.max} Per Year`}</p>
                  </li>
                  <li>
                    <i className="icon-36"></i>
                    <h6>Application Period</h6>
                    <p>{`${new Date(
                      currentJob.postedDate
                    ).toLocaleDateString()} - ${new Date(
                      currentJob.expireDate
                    ).toLocaleDateString()}`}</p>
                  </li>
                  <li>
                    <i className="icon-37"></i>
                    <h6>Experience</h6>
                    <p>{currentJob.experience.join(", ")}</p>
                  </li>
                  <li>
                    <i className="icon-34"></i>
                    <h6>Job Location</h6>
                    <p>{currentJob.location}</p>
                  </li>
                  <li>
                    <i className="icon-32"></i>
                    <h6>Age Limit</h6>
                    <p>{`Age ${currentJob.ageLimit.min} to ${currentJob.ageLimit.max} years old`}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CareerDetails;
