function BlogSkeletonLoader() {
  const skeletonStyles = {
    "@keyframes loading": {
      "0%": { backgroundPosition: "200% 0" },
      "100%": { backgroundPosition: "-200% 0" },
    },
    skeletonBase: {
      background:
        "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
      backgroundSize: "200% 100%",
      animation: "loading 1.5s infinite",
    },
    skeletonImage: {
      width: "100%",
      height: "200px",
    },
    skeletonDate: {
      width: "60px",
      height: "60px",
      marginBottom: "10px",
    },
    skeletonTitle: {
      width: "80%",
      height: "24px",
      marginBottom: "10px",
    },
    skeletonText: {
      width: "100%",
      height: "80px",
      marginBottom: "10px",
    },
    skeletonInfo: {
      width: "70%",
      height: "20px",
    },
  };
  return (
    <>
      {[1, 2, 3].map((index) => (
        <div key={index} className="col-lg-4 col-md-6 col-sm-12 news-block">
          <div className="news-block-two wow fadeInUp animated">
            <div className="inner-box">
              <div className="image-box">
                <div
                  style={{
                    ...skeletonStyles.skeletonBase,
                    ...skeletonStyles.skeletonImage,
                  }}
                ></div>
              </div>
              <div className="lower-content">
                <div
                  style={{
                    ...skeletonStyles.skeletonBase,
                    ...skeletonStyles.skeletonDate,
                  }}
                ></div>
                <div
                  style={{
                    ...skeletonStyles.skeletonBase,
                    ...skeletonStyles.skeletonTitle,
                  }}
                ></div>
                <div
                  style={{
                    ...skeletonStyles.skeletonBase,
                    ...skeletonStyles.skeletonText,
                  }}
                ></div>
                <div
                  style={{
                    ...skeletonStyles.skeletonBase,
                    ...skeletonStyles.skeletonInfo,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <style jsx>{`
        @keyframes loading {
          0% {
            background-position: 200% 0;
          }
          100% {
            background-position: -200% 0;
          }
        }
      `}</style>
    </>
  );
}
export default BlogSkeletonLoader;
