import axios from "axios";
import {
  fetchBankingServicesStart,
  getBankingServicesSuccess,
  getBankingServicesError,
} from "../slices/bankingServicesSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchBankingServices = (bankingType) => async (dispatch) => {
  dispatch(fetchBankingServicesStart());
  try {
    const res = await axios.get(`${API_URL}/bankings`, {
      headers: getAuthHeader(),
    });
    dispatch(getBankingServicesSuccess(res.data.data));
  } catch (err) {
    console.log("Get Banking Services Error:", err.response);
    dispatch(
      getBankingServicesError(err.response?.data?.error || "An error occurred")
    );
  }
};
