import axios from "axios";
import {
  fetchTeamMembersStart,
  getTeamMembersSuccess,
  getTeamMembersError,
} from "../slices/teamsSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchTeamMembers = () => async (dispatch) => {
  dispatch(fetchTeamMembersStart());
  try {
    const res = await axios.get(`${API_URL}/team-members`, {
      headers: getAuthHeader(),
    });
    dispatch(getTeamMembersSuccess(res.data.data));
  } catch (err) {
    console.log("Get Team Members Error:", err.response);
    dispatch(getTeamMembersError(err.response.data.error));
  }
};

export const fetchTeamMemberById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/team-members/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getTeamMembersSuccess([res.data.data]));
  } catch (err) {
    console.log("Get Blog Error:", err.response);
    dispatch(getTeamMembersError(err.response.data.error));
  }
};
