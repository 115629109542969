const TabContent = ({
  title,
  description,
  item1Title,
  item2Title,
  items1,
  items2,
  imageUrl,
  imageAlt,
}) => (
  <div className="row clearfix">
    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
      <div className="content_block_four">
        <div className="content-box mr_110">
          <h2>{title}</h2>
          <p>{description}</p>
          <ul className="list-item mb_40 clearfix">
            {item1Title && <h3>{item1Title}</h3>}
            {items1 &&
              items1.map((item1, index) => <li key={index}>{item1}</li>)}
            {item2Title && <h3 className="mt-5">{item2Title}</h3>}
            {items2 &&
              items2.map((item2, index) => <li key={index}>{item2}</li>)}
          </ul>
        </div>
      </div>
    </div>
    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
      <div className="image-box ml_40">
        <div
          className="image-shape"
          style={{ backgroundImage: "url(assets/images/shape/shape-3.png)" }}
        ></div>
        <figure className="image">
          <img src={imageUrl} alt={imageAlt} />
        </figure>
      </div>
    </div>
  </div>
);

export default TabContent;
