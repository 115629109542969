function ProcessSection() {
  return (
    <section className="process-section centred pt_120 pb_90">
      <div
        className="bg-layer"
        style={{
          backgroundImage: "url(assets/images/open-account.jpg)",
        }}
      ></div>
      <div className="auto-container">
        <div className="sec-title mb_110">
          <h6>Our process</h6>
          <h2>Open Bank Accounts</h2>
        </div>
        <div className="inner-container">
          <div className="processing-block-one">
            <div
              className="arrow-shape"
              style={{
                backgroundImage: "url(assets/images/shape/shape-12.png)",
              }}
            ></div>
            <div className="inner-box">
              <span className="count-text">
                01 <br />
                Step
              </span>
              <h3>
                Fill In The <br />
                Desired Account
              </h3>
              <p>
                Select from a range of accounts, including Personal Savings /
                Current Accounts, and Business Current / Saving Accounts, and
                Fixed Deposit Account.
              </p>
            </div>
          </div>
          <div className="processing-block-one">
            <div
              className="arrow-shape"
              style={{
                backgroundImage: "url(assets/images/shape/shape-13.png)",
              }}
            ></div>
            <div className="inner-box">
              <span className="count-text">
                02 <br />
                Step
              </span>
              <h3>
                Complete the <br />
                Application Form
              </h3>
              <p>
                Provide the necessary details and submit required documents to
                get started.
              </p>
            </div>
          </div>
          <div className="processing-block-one">
            <div className="inner-box">
              <span className="count-text">
                03 <br />
                Step
              </span>
              <h3>
                Access Your <br />
                Account
              </h3>
              <p>
                Enjoy easy and secure access to your new account, with services
                like SMS Alerts and more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ProcessSection;
