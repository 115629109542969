import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const BlogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    getBlogsSuccess: (state, action) => {
      state.items = action.payload;
      state.status = "succeeded";
    },
    getBlogsError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchBlogsStart: (state) => {
      state.status = "loading";
    },
  },
});

export const { getBlogsSuccess, getBlogsError, fetchBlogsStart } =
  BlogsSlice.actions;
export default BlogsSlice.reducer;
