import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const bankingServicesSlice = createSlice({
  name: "bankingServices",
  initialState,
  reducers: {
    getBankingServicesSuccess: (state, action) => {
      state.status = "succeeded";
      state.items = action.payload;
    },
    getBankingServicesError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchBankingServicesStart: (state) => {
      state.status = "loading";
    },
  },
});

export const {
  getBankingServicesSuccess,
  getBankingServicesError,
  fetchBankingServicesStart,
} = bankingServicesSlice.actions;

export default bankingServicesSlice.reducer;
