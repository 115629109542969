import { Link } from "react-router-dom";

function ServiceBlock({ imageUrl, title, description, delay, link }) {
  return (
    <div className="col-lg-4 col-md-6 col-sm-12 service-block">
      <div
        className="service-block-one wow fadeInUp animated"
        data-wow-delay={delay}
        data-wow-duration="1500ms"
      >
        <div className="inner-box">
          <div className="shape"></div>
          <div className="image-box">
            <img
              src={imageUrl}
              alt={title}
              width={64} // Set your desired width
              height={64} // Set your desired height
              style={{ objectFit: "cover" }}
            />
          </div>
          <h4>{title}</h4>
          <p>{description}</p>
          <div className="btn-box">
            <Link to={link} className="theme-btn btn-one">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceBlock;
