import { createSlice } from "@reduxjs/toolkit";

const branchesData = {
  "Grand Bassa County": [
    {
      name: "BUCHANAN BRANCH",
      address:
        "Tubman and Preston Street intersection Opposite the Dream Primary School. Grand Bassa, Liberia",
    },
  ],
  "Montserado County": [
    {
      name: "SINKOR BRANCH",
      address:
        "20th Street Tubman Boulevard Opposite Maritime Monrovia, Liberia",
    },
    { name: "DUALA BRANCH", address: "Caldwell Junction Monrovia, Liberia" },
    {
      name: "ELWA BRANCH",
      address:
        "Rehab Junction opposite Conex Oil and Gas Station. Monrovia, Liberia",
    },
    {
      name: "GARDNERSVILLE BRANCH",
      address: "Somalia Drive Adjacent old Aluminum Factory Monrovia, Liberia",
    },
    {
      name: "JOHNSON STREET BRANCH",
      address:
        "Intersection of Ashmum and Johnson Street. Adjacent Slip way football field Monrovia, Liberia",
    },
    {
      name: "RED-LIGHT BRANCH",
      address: "Duala Packing Paynesville Red-Light Paynesville, Liberia",
    },
  ],
  "Margibi County": [
    { name: "KAKATA BRANCH", address: "Kakata City Kakata, Liberia" },
  ],
  "Bong County": [
    {
      name: "GBARNGA BRANCH",
      address:
        "Sanniquellie-Yekepa Parking, Sanniquellie- Guinea Junction Ganta City, Nimba County, Liberia",
    },
  ],
  "Nimba County": [
    {
      name: "GANTA BRANCH",
      address:
        "Sanniquellie-Yekepa Parking, Sanniquellie- Guinea Junction Ganta City, Nimba County, Liberia",
    },
  ],
};

const initialState = {
  branches: branchesData,
  selectedBranch: "",
  location: "",
  status: "idle",
  error: null,
};

const branchFinderSlice = createSlice({
  name: "branchFinder",
  initialState,
  reducers: {
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
  },
});

export const { setSelectedBranch, setLocation } = branchFinderSlice.actions;

export default branchFinderSlice.reducer;
