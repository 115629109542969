const FeatureBlockData = [
  {
    icon: "icon-5",
    title: "Ease and speed of access",
    description:
      "We provide financial services to previously un-or under-served parts of the population, with the aim to be faster and more efficient than any comparable operator in the country.",
  },
  {
    icon: "icon-9",
    title: "Transparency and clarity",
    description:
      "We communicate all products, services, and eligibility criteria with as little room for subjective interpretation as possible.",
  },
  {
    icon: "icon-12",
    title: "Quality leadership",
    description:
      "We set industry standards with respect to customer service and quality, ensuring the highest quality loan portfolios possible, through adherence to international best practices with respect to their underwriting standards and credit risk management.",
  },
  {
    icon: "icon-11",
    title: "Strong business ethics",
    description:
      "We adhere to a joint set of principles of good corporate behavior, covering responsible lending practices, adherence to social and environmental standards, both international and local, as well as a stringent code of conduct for all member-bank employees.",
  },
];

export default FeatureBlockData;
