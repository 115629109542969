import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReportsSuccess(state, action) {
      state.status = "succeeded";
      state.items = action.payload;
    },
    getReportsError(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchReportsStart(state) {
      state.status = "loading";
    },
  },
});

export const { fetchReportsStart, getReportsSuccess, getReportsError } =
  reportsSlice.actions;

export default reportsSlice.reducer;
