import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    getTestimonialsSuccess: (state, action) => {
      state.items = action.payload;
      state.status = "succeeded";
    },
    getTestimonialsError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchTestimonialsStart: (state) => {
      state.status = "loading";
    },
  },
});

export const {
  getTestimonialsSuccess,
  getTestimonialsError,
  fetchTestimonialsStart,
} = testimonialsSlice.actions;
export default testimonialsSlice.reducer;
