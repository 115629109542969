import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const ServiceTabSection = ({ title, backgroundImage, tabs, defaultTab }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      // Tabs Box functionality
      if ($(".tabs-box").length) {
        $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
          e.preventDefault();
          var target = $($(this).attr("data-tab"));

          if ($(target).is(":visible")) {
            return false;
          } else {
            target
              .parents(".tabs-box")
              .find(".tab-buttons")
              .find(".tab-btn")
              .removeClass("active-btn");
            $(this).addClass("active-btn");
            target
              .parents(".tabs-box")
              .find(".tabs-content")
              .find(".tab")
              .fadeOut(0);
            target
              .parents(".tabs-box")
              .find(".tabs-content")
              .find(".tab")
              .removeClass("active-tab");
            $(target).fadeIn(100);
            $(target).addClass("active-tab");
          }
        });
      }

      // Scroll to section function
      const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
          setTimeout(() => {
            const yOffset = -120; // Adjust this value to match the sticky header height
            const y =
              element.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
            // Activate the corresponding tab
            const tabBtn = document.querySelector(`[data-tab="#${sectionId}"]`);
            if (tabBtn) {
              tabBtn.click();
            }
          }, 100); // Small delay to ensure DOM is ready
        }
      };

      // Handle hash changes
      const handleHashChange = () => {
        if (window.location.hash) {
          const sectionId = window.location.hash.replace("#", "");
          scrollToSection(sectionId);
          setActiveTab(sectionId);
        }
      };

      window.addEventListener("hashchange", handleHashChange);

      // Initial load
      if (location.hash) {
        handleHashChange();
      }

      return () => {
        window.removeEventListener("hashchange", handleHashChange);
      };
    }
  }, [location]);

  useEffect(() => {
    // This effect will run on every location change
    if (location.hash) {
      const sectionId = location.hash.replace("#", "");
      const element = document.getElementById(sectionId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
          setActiveTab(sectionId);
        }, 100);
      }
    }
  }, [location]);

  if (tabs.length === 0) {
    return (
      <section className="service-style-two pb_120">
        <div className="auto-container">
          <div className="sec-title centred mb_70">
            <h2>{title}</h2>
          </div>
          <p>No approved banking services available at this time.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="service-style-two pb_120">
      <div
        className="bg-layer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="auto-container">
        <div className="sec-title centred mb_70">
          <h2>{title}</h2>
        </div>
        <div className="tabs-box">
          <div className="tab-btn-box p_relative mb_100">
            <ul className="tab-btns tab-buttons">
              {tabs.map((tab) => (
                <li
                  key={tab.id}
                  className={`tab-btn ${
                    activeTab === tab.id ? "active-btn" : ""
                  }`}
                  data-tab={`#${tab.id}`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <span>{tab.label}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="tabs-content">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`tab ${
                  activeTab === tab.id ? "active-tab" : ""
                } section-padding-top`}
                id={tab.id}
              >
                {tab.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceTabSection;
