import PageTitle from "../components/custom/PageTitle";
import AboutSection from "../components/AboutSection";
import WhyChooseUs from "../components/WhyChooseUs";

function AboutUs() {
  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="About Us"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "About Us" }]}
      />

      <AboutSection />

      <WhyChooseUs />
    </div>
  );
}
export default AboutUs;
