import { Link } from "react-router-dom";

const PageTitle = ({
  title,
  backgroundImage = "assets/images/ablBanner.jpg",
  pattern1Image = "assets/images/shape/shape-18.png",
  pattern2Image = "assets/images/shape/shape-17.png",
  breadcrumbs = [],
}) => {
  return (
    <section className="page-title centred">
      <div
        className="bg-layer"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      <div className="pattern-layer">
        <div
          className="pattern-1"
          style={{ backgroundImage: `url(${pattern1Image})` }}
        ></div>
        <div
          className="pattern-2"
          style={{ backgroundImage: `url(${pattern2Image})` }}
        ></div>
      </div>
      <div className="auto-container">
        <div className="content-box">
          <h1>{title}</h1>
          <ul className="bread-crumb clearfix">
            {breadcrumbs.map((crumb, index) => (
              <li key={index}>
                {crumb.link ? (
                  <Link href={crumb.link}>{crumb.text}</Link>
                ) : (
                  crumb.text
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
