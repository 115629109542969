import { useEffect } from "react";
import $ from "jquery";

function PreLoader() {
  useEffect(() => {
    //Hide Loading Box (Preloader)
    function handlePreloader() {
      if ($(".loader-wrap").length) {
        $(".loader-wrap").delay(1000).fadeOut(500);
      }
    }

    // Call handlePreloader when the component mounts
    handlePreloader();

    if ($(".preloader-close").length) {
      $(".preloader-close").on("click", function () {
        $(".loader-wrap").delay(200).fadeOut(500);
      });
    }

    // Clean up event listener when component unmounts
    return () => {
      $(".preloader-close").off("click");
    };
  }, []);

  return (
    <div className="loader-wrap">
      <div className="preloader">
        <div className="preloader-close">close</div>
        <div id="handle-preloader" className="handle-preloader">
          <div className="animation-preloader">
            <div className="spinner"></div>
            <div className="txt-loading">
              {["A", "c", "c", "e", "s", "s", "b", "a", "n", "k"].map(
                (letter, index) => (
                  <span
                    key={index}
                    data-text-preloader={letter}
                    className="letters-loading"
                  >
                    {letter}
                  </span>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreLoader;
