import { useEffect, useRef, useState } from "react";

function FunFactSection() {
  const [counts, setCounts] = useState([0, 0, 0]);
  const countBoxesRef = useRef([]);
  const observerRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1,
      root: null,
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = countBoxesRef.current.indexOf(entry.target);
          if (index !== -1) {
            animateCount(index);
            observerRef.current.unobserve(entry.target);
          }
        }
      });
    }, options);

    countBoxesRef.current.forEach((box) => {
      if (box) observerRef.current.observe(box);
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const animateCount = (index) => {
    const countText = countBoxesRef.current[index].querySelector(".count-text");
    const target = parseInt(countText.getAttribute("data-stop"), 10);
    const duration = parseInt(countText.getAttribute("data-speed"), 10);
    const step = (target / duration) * 16; // 16ms is roughly 60fps

    let current = 0;
    const updateCount = () => {
      current += step;
      if (current < target) {
        setCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          newCounts[index] = Math.floor(current);
          return newCounts;
        });
        requestAnimationFrame(updateCount);
      } else {
        setCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          newCounts[index] = target;
          return newCounts;
        });
      }
    };

    requestAnimationFrame(updateCount);
  };

  const countData = [
    { icon: "icon-35", suffix: "", label: "Years" },
    { icon: "icon-21", suffix: "K+", label: "Total Customers" },
    { icon: "icon-2", suffix: "", label: "Branches" },
  ];

  return (
    <section className="funfact-style-two">
      <div
        className="bg-layer"
        style={{
          backgroundImage: "url(assets/images/background/funfact-bg1.gif)",
        }}
      ></div>
      <div className="auto-container">
        <div className="row clearfix">
          {countData.map((item, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 funfact-block"
            >
              <div
                className="funfact-block-one"
                ref={(el) => (countBoxesRef.current[index] = el)}
              >
                <div className="inner-box">
                  <div className="icon-box">
                    <i className={item.icon}></i>
                  </div>
                  <div className="count-outer count-box">
                    <span
                      className="count-text"
                      data-speed="1500"
                      data-stop={
                        index === 0 ? "15" : index === 1 ? "200" : "10"
                      }
                    >
                      {counts[index]}
                    </span>
                    <span>{item.suffix}</span>
                  </div>
                  <p>{item.label}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FunFactSection;
