const BannerSkeleton = () => {
  // Define the styles
  const styles = {
    bannerSection: {
      height: "600px",
      backgroundColor: "#0e3c6e",
      position: "relative",
      overflow: "hidden",
    },
    backgroundGradient: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "linear-gradient(45deg, #0e3c6e 30%, transparent 70%)",
      animation: "gradient-shift 2s ease-in-out infinite alternate",
    },
    container: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 15px",
      maxWidth: "1140px",
      margin: "0 auto",
      position: "relative",
      zIndex: 1,
    },
    contentBox: {
      maxWidth: "100%",
    },
    skeletonText: {
      height: "2rem",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      marginBottom: "1rem",
      borderRadius: "0.25rem",
      animation: "skeleton-loading 1s linear infinite alternate",
    },
    skeletonButton: {
      width: "250px",
      height: "50px",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      borderRadius: "0.25rem",
      marginTop: "2rem",
      animation: "skeleton-loading 1s linear infinite alternate",
    },
  };

  // Define the keyframes animations
  const keyframes = `
    @keyframes skeleton-loading {
      0% {
        background-color: rgba(255, 255, 255, 0.2);
      }
      100% {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    @keyframes gradient-shift {
      0% {
        background-position: 0% 50%;
      }
      100% {
        background-position: 100% 50%;
      }
    }
  `;

  return (
    <>
      <style>{keyframes}</style>
      <section style={styles.bannerSection}>
        <div style={styles.backgroundGradient}></div>
        <div style={styles.container}>
          <div style={styles.contentBox}>
            <div style={{ ...styles.skeletonText, width: "100%" }}></div>
            <div style={{ ...styles.skeletonText, width: "60%" }}></div>
            <div
              style={{
                ...styles.skeletonText,
                width: "80%",
                marginTop: "50px",
              }}
            ></div>
            <div
              style={{
                ...styles.skeletonText,
                width: "70%",
                marginBottom: "50px",
              }}
            ></div>
            <div style={styles.skeletonButton}></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerSkeleton;
