import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamMemberById } from "../redux/actions/teams";
import PageTitle from "../components/custom/PageTitle";

function TeamDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    items: teamMembers,
    status,
    error,
  } = useSelector((state) => state.teams);

  useEffect(() => {
    if (id) {
      dispatch(fetchTeamMemberById(id));
    }
  }, [id, dispatch]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  const member = teamMembers.find((member) => member._id === id);

  if (!member) {
    return <div>Team member not found</div>;
  }

  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title={`${member.name} - Details`}
        breadcrumbs={[{ text: "Home", link: "/" }, { text: member.name }]}
      />

      <section className="team-details pt_120 pb_120">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-7 col-md-12 col-sm-12 content-column">
              <div className="content-box mr_25">
                <h2>{member.name}</h2>
                <span className="designation">{member.position}</span>
                <p>{member.bio}</p>

                <h3>Contact Details</h3>

                <ul className="social-links clearfix">
                  {member.socialMediaLinks.facebook && (
                    <li>
                      <a href={member.socialMediaLinks.facebook}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                  )}
                  {member.socialMediaLinks.twitter && (
                    <li>
                      <a href={member.socialMediaLinks.twitter}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                  )}
                  {member.socialMediaLinks.linkedin && (
                    <li>
                      <a href={member.socialMediaLinks.linkedin}>
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 image-column">
              <div className="image_block_three">
                <div className="image-box pl_80">
                  <div className="image-shape">
                    <div
                      className="shape-1"
                      style={{
                        backgroundImage: "url(assets/images/shape/shape-3.png)",
                      }}
                    ></div>
                    <div
                      className="shape-2"
                      style={{
                        backgroundImage:
                          "url(assets/images/shape/shape-11.png)",
                      }}
                    ></div>
                  </div>
                  <figure className="image pt-md-3 pt-lg-5">
                    <img src={member.teamMemberImage} alt={member.name} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TeamDetails;
