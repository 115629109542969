import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  comments: [],
  loading: false,
  error: null,
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    fetchCommentsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCommentsSuccess(state, action) {
      state.comments = action.payload;
      state.loading = false;
    },
    fetchCommentsError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    addCommentSuccess(state, action) {
      state.comments.push(action.payload);
    },
    updateCommentSuccess(state, action) {
      const index = state.comments.findIndex(
        (comment) => comment._id === action.payload._id
      );
      if (index !== -1) {
        state.comments[index] = action.payload;
      }
    },
    deleteCommentSuccess(state, action) {
      state.comments = state.comments.filter(
        (comment) => comment._id !== action.payload
      );
    },
    addCommentError(state, action) {
      state.error = action.payload;
    },
    updateCommentError(state, action) {
      state.error = action.payload;
    },
    deleteCommentError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsError,
  addCommentSuccess,
  addCommentError,
  updateCommentSuccess,
  updateCommentError,
  deleteCommentSuccess,
  deleteCommentError,
} = commentsSlice.actions;

export default commentsSlice.reducer;
