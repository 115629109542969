import { Link } from "react-router-dom";
import PageTitle from "../components/custom/PageTitle";

function NotFound() {
  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="404 Error"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "404" }]}
      />

      <section className="error-section centred pt_120 pb_120">
        <div className="auto-container">
          <div className="inner-box">
            <figure className="error-image">
              <img src="assets/images/icons/error-1.png" alt="" />
            </figure>
            <h2>Sorry, Something Went Wrong.</h2>
            <Link to="/" className="theme-btn btn-one">
              Back to Homepage
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
export default NotFound;
