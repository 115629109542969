import PageTitle from "../components/custom/PageTitle";
import TariffTable from "../components/TariffTable";

function Tariff() {
  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Tariff Page"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Tariff Page" }]}
      />

      <TariffTable />
    </div>
  );
}
export default Tariff;
