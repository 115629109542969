import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    getBannersSuccess: (state, action) => {
      state.status = "succeeded";
      state.items = action.payload;
    },
    getBannersError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchBannersStart: (state) => {
      state.status = "loading";
    },
  },
});

export const { getBannersSuccess, getBannersError, fetchBannersStart } =
  bannersSlice.actions;
export default bannersSlice.reducer;
