import axios from "axios";
import {
  fetchJobsStart,
  getJobsSuccess,
  getJobsError,
  fetchJobDetailsStart,
  getJobDetailsSuccess,
  getJobDetailsError,
} from "../slices/jobsSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchJobs = () => async (dispatch) => {
  dispatch(fetchJobsStart());
  try {
    const res = await axios.get(`${API_URL}/jobs`, {
      headers: getAuthHeader(),
    });
    dispatch(getJobsSuccess(res.data.data));
  } catch (err) {
    console.log("Get Jobs Error:", err.response);
    dispatch(getJobsError(err.response?.data?.error || "An error occurred"));
  }
};

export const fetchJobDetails = (id) => async (dispatch) => {
  dispatch(fetchJobDetailsStart());
  try {
    const res = await axios.get(`${API_URL}/jobs/${id}`, {
      headers: getAuthHeader(),
    });
    dispatch(getJobDetailsSuccess(res.data.data));
  } catch (err) {
    console.log("Get Job Details Error:", err.response);
    dispatch(
      getJobDetailsError(err.response?.data?.error || "An error occurred")
    );
  }
};
