import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
  currentJob: null,
  currentJobStatus: "idle",
  currentJobError: null,
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    fetchJobsStart(state) {
      state.status = "loading";
    },
    getJobsSuccess(state, action) {
      state.status = "succeeded";
      state.items = action.payload;
    },
    getJobsError(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchJobDetailsStart(state) {
      state.currentJobStatus = "loading";
    },
    getJobDetailsSuccess(state, action) {
      state.currentJobStatus = "succeeded";
      state.currentJob = action.payload;
    },
    getJobDetailsError(state, action) {
      state.currentJobStatus = "failed";
      state.currentJobError = action.payload;
    },
  },
});

export const {
  fetchJobsStart,
  getJobsSuccess,
  getJobsError,
  fetchJobDetailsStart,
  getJobDetailsSuccess,
  getJobDetailsError,
} = jobsSlice.actions;

export default jobsSlice.reducer;
