import axios from "axios";
import {
  fetchTariffsStart,
  getTariffsSuccess,
  getTariffsError,
} from "../slices/tariffsSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchTariffs = () => async (dispatch) => {
  dispatch(fetchTariffsStart());
  try {
    const res = await axios.get(`${API_URL}/tariffs`, {
      headers: getAuthHeader(),
    });
    dispatch(getTariffsSuccess(res.data.data));
  } catch (err) {
    console.log("Get Tariffs Error:", err.response);
    dispatch(getTariffsError(err.response.data.error));
  }
};
