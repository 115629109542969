const FeatureBlock = ({ icon, title, description }) => {
  return (
    <div className="feature-block-one">
      <div className="inner-box">
        <div className="icon-box">
          <i className={icon}></i>
        </div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default FeatureBlock;
