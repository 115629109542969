import axios from "axios";
import {
  fetchCommentsStart,
  fetchCommentsSuccess,
  fetchCommentsError,
  addCommentSuccess,
  addCommentError,
  updateCommentSuccess,
  updateCommentError,
  deleteCommentSuccess,
  deleteCommentError,
} from "../slices/commentsSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchComments = (blogId) => async (dispatch) => {
  dispatch(fetchCommentsStart());
  try {
    const res = await axios.get(`${API_URL}/comments?post=${blogId}`, {
      headers: getAuthHeader(),
    });
    dispatch(fetchCommentsSuccess(res.data.data));
  } catch (err) {
    console.error("Fetch Comments Error:", err.response);
    dispatch(fetchCommentsError(err.response.data.error));
  }
};

export const addComment = (commentData) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/comments`, commentData, {
      headers: getAuthHeader(),
    });
    dispatch(addCommentSuccess(res.data.data));
  } catch (err) {
    console.error("Add Comment Error:", err.response);
    dispatch(addCommentError(err.response.data.error));
  }
};

export const updateComment = (commentId, commentData) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${API_URL}/comments/${commentId}`,
      commentData,
      {
        headers: getAuthHeader(),
      }
    );
    dispatch(updateCommentSuccess(res.data.data));
  } catch (err) {
    console.error("Update Comment Error:", err.response);
    dispatch(updateCommentError(err.response.data.error));
  }
};

export const deleteComment = (commentId) => async (dispatch) => {
  try {
    await axios.delete(`${API_URL}/comments/${commentId}`, {
      headers: getAuthHeader(),
    });
    dispatch(deleteCommentSuccess(commentId));
  } catch (err) {
    console.error("Delete Comment Error:", err.response);
    dispatch(deleteCommentError(err.response.data.error));
  }
};
