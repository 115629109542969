import axios from "axios";
import {
  fetchReportsStart,
  getReportsSuccess,
  getReportsError,
} from "../slices/reportsSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchReports = () => async (dispatch) => {
  dispatch(fetchReportsStart());
  try {
    const res = await axios.get(`${API_URL}/reports`, {
      headers: getAuthHeader(),
    });
    dispatch(getReportsSuccess(res.data.data));
  } catch (err) {
    console.log("Get Reports Error:", err.response);
    dispatch(getReportsError(err.response.data.error));
  }
};
