const DownloadReportSkeleton = () => {
  const styles = {
    container: {
      maxWidth: "1140px",
      padding: "40px 15px",
      margin: "50px auto",
    },
    title: {
      height: "24px",
      width: "200px",
      backgroundColor: "#e0e0e0",
      margin: "0 auto 10px",
      borderRadius: "4px",
    },
    heading: {
      height: "40px",
      width: "80%",
      backgroundColor: "#e0e0e0",
      margin: "0 auto 40px",
      borderRadius: "4px",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      gap: "20px",
    },
    card: {
      padding: "20px",
      backgroundColor: "#f5f5f5",
      borderRadius: "8px",
    },
    cardTitle: {
      height: "24px",
      width: "80%",
      backgroundColor: "#e0e0e0",
      marginBottom: "20px",
      borderRadius: "4px",
    },
    button: {
      height: "40px",
      width: "120px",
      backgroundColor: "#e0e0e0",
      borderRadius: "4px",
    },
  };

  const pulseAnimation = `
    @keyframes pulse {
      0% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.6;
      }
    }
  `;

  return (
    <div style={styles.container}>
      <style>{pulseAnimation}</style>
      <div style={{ ...styles.title, animation: "pulse 1.5s infinite" }}></div>
      <div
        style={{ ...styles.heading, animation: "pulse 1.5s infinite" }}
      ></div>
      <div style={styles.grid}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
          <div key={item} style={styles.card}>
            <div
              style={{ ...styles.cardTitle, animation: "pulse 1.5s infinite" }}
            ></div>
            <div
              style={{ ...styles.button, animation: "pulse 1.5s infinite" }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DownloadReportSkeleton;
