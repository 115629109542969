import PageTitle from "../components/custom/PageTitle";
import AboutSection from "../components/AboutSection";
import CareerSection from "../components/CareerSection";

function Career() {
  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Career Page"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Career" }]}
      />

      <AboutSection />

      <CareerSection />
    </div>
  );
}
export default Career;
