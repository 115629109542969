import PageTitle from "../components/custom/PageTitle";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";

const data = [
  { name: "AccessHolding", value: 74, color: "#3366cc" },
  { name: "AfDB", value: 14, color: "#109618" },
  { name: "EIB", value: 12, color: "#990099" },
];

function ShareHolders() {
  return (
    <div className="box_wrapper ltr">
      <PageTitle
        title="Share Holders"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Share Holders" }]}
      />
      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-lg-12 col-md-6 ">
            <ResponsiveContainer width="95%" height={500}>
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  // outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="col-lg-12 col-md-6 mt-5 mb-5">
            <div className="mb-3 mt-5">
              <div className="card-body">
                <h5 className="card-title mb-3">
                  Access Microfinance Holding AG (AccessHolding) 74%
                </h5>
                <p className="card-text">
                  AccessHolding is a commercial microfinance holding company
                  that invests in microfinance institutions (MFIs) and develops
                  these investments through a combination of equity finance,
                  holding services, and management assistance. These services
                  are rendered by its technical partner and founding shareholder
                  LFS Financial Systems GmbH, with which AccessHolding shares
                  premises in Berlin, Germany.
                </p>
                <p className="card-text mt-2">
                  AccessHolding establishes new MFIs together with other
                  partners, and transforms existing non-bank micro lending
                  institutions into full-service microfinance banks. Currently,
                  there are eight AccessBanks operating in Azerbaijan, Liberia,
                  Madagascar, Nigeria, Rwanda, Tajikistan, Tanzania and Zambia.
                </p>
              </div>
            </div>
            <div className="mb-3">
              <div className="card-body">
                <h5 className="card-title mb-3">
                  African Development Bank 14%
                </h5>
                <p className="card-text">
                  The African Development Bank (AfDB) is a multilateral
                  development finance institution established to contribute to
                  the economic development and social progress of African
                  countries. The AfDB was founded in 1964. The AfDB’s mission is
                  to fight poverty and improve living conditions on the
                  continent through promoting the investment of public and
                  private capital in projects and programs that are likely to
                  contribute to the economic and social development of the
                  region.TheAfDB is a financial provider to African governments
                  and private companies investing in the regional member
                  countries (RMC).
                </p>
              </div>
            </div>
            <div>
              <div className="card-body">
                <h5 className="card-title mb-3">
                  The European Investment Bank 12%
                </h5>
                <p className="card-text">
                  The European Investment Bank (EIB) is Europe’s long-term
                  lending bank, created by the Treaty of Rome in 1958. The task
                  of the Bank is to contribute towards the integration, balanced
                  development and economic and social cohesion of the EU Member
                  States. As a shareholder of AccessHolding, EIB is closely
                  involved in the build-up of the emerging AccessBank network in
                  Africa and the world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ShareHolders;
