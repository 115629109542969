import { useState } from "react";

function CalculatorSection() {
  const [loanAmount, setLoanAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [totalInterest, setTotalInterest] = useState("");
  const [error, setError] = useState("");

  const calculateResults = (e) => {
    e.preventDefault();

    const principal = parseFloat(loanAmount);
    const calculatedInterest = parseFloat(interestRate) / 100 / 12;
    const calculatedPayment = parseFloat(loanTerm) * 12;

    const x = Math.pow(1 + calculatedInterest, calculatedPayment);
    const monthly = (principal * x * calculatedInterest) / (x - 1);

    if (isFinite(monthly)) {
      setMonthlyPayment(monthly.toFixed(2));
      setTotalPayment((monthly * calculatedPayment).toFixed(2));
      setTotalInterest((monthly * calculatedPayment - principal).toFixed(2));
      setError("");
    } else {
      setError("Please check your numbers");
    }
  };

  const resetData = () => {
    setLoanAmount("");
    setInterestRate("");
    setLoanTerm("");
    setMonthlyPayment("");
    setTotalPayment("");
    setTotalInterest("");
    setError("");
  };

  return (
    <section className="calculator-section pt_120 pb_120">
      <div
        className="light-icon float-bob-y"
        style={{ backgroundImage: "url(assets/images/icons/icon-1.png)" }}
      ></div>
      <div className="auto-container">
        <div className="inner-container">
          <div
            className="shape"
            style={{
              backgroundImage: "url(assets/images/shape/shape-3.png)",
            }}
          ></div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_two">
                <div className="content-box">
                  <div className="sec-title mb_50">
                    <h6>Calculate Loan</h6>
                    <h2>Online EMI Calculator</h2>
                  </div>
                  <div className="calculator-inner">
                    <form id="loan-form" onSubmit={calculateResults}>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="number"
                            id="amount"
                            value={loanAmount}
                            onChange={(e) => setLoanAmount(e.target.value)}
                            placeholder="Loan amount"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          id="years"
                          value={loanTerm}
                          onChange={(e) => setLoanTerm(e.target.value)}
                          placeholder="Number of months"
                        />
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="number"
                            id="interest"
                            value={interestRate}
                            onChange={(e) => setInterestRate(e.target.value)}
                            placeholder="Interest rate"
                          />
                        </div>
                      </div>
                      <div className="form-group d-flex">
                        <input
                          type="submit"
                          value="Calculate"
                          className="theme-btn btn-one mr_20"
                        />
                        <input
                          type="button"
                          value="Reset Data"
                          className="reset-btn"
                          onClick={resetData}
                        />
                      </div>
                    </form>

                    {error && <div className="alert alert-danger">{error}</div>}

                    <div id="results">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="number"
                            placeholder="Monthly Installment is"
                            id="monthly-payment"
                            value={monthlyPayment}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group none">
                        <div className="input-group">
                          <input
                            type="number"
                            id="total-payment"
                            value={totalPayment}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group none">
                        <div className="input-group">
                          <input
                            type="number"
                            id="total-interest"
                            value={totalInterest}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <figure className="image-box">
                <img src="assets/images/loan-calculator.gif" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CalculatorSection;
