const TariffSkeleton = () => {
  const styles = {
    container: {
      maxWidth: "1140px",
      margin: "0 auto",
      padding: "40px 15px",
    },
    title: {
      height: "40px",
      width: "80%",
      backgroundColor: "#e0e0e0",
      marginBottom: "20px",
      borderRadius: "4px",
    },
    date: {
      height: "24px",
      width: "40%",
      backgroundColor: "#e0e0e0",
      marginBottom: "40px",
      borderRadius: "4px",
    },
    sectionTitle: {
      height: "30px",
      width: "60%",
      backgroundColor: "#e0e0e0",
      marginBottom: "20px",
      borderRadius: "4px",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: "0 8px",
    },
    tableHeader: {
      height: "40px",
      backgroundColor: "#0e3c6e",
    },
    tableCell: {
      height: "30px",
      backgroundColor: "#e0e0e0",
      margin: "4px 0",
    },
  };

  const pulseAnimation = `
    @keyframes pulse {
      0% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.6;
      }
    }
  `;

  return (
    <div style={styles.container}>
      <style>{pulseAnimation}</style>
      <div style={{ ...styles.title, animation: "pulse 1.5s infinite" }}></div>
      <div style={{ ...styles.date, animation: "pulse 1.5s infinite" }}></div>

      {[1, 2].map((section) => (
        <div key={section} style={{ marginBottom: "40px" }}>
          <div
            style={{ ...styles.sectionTitle, animation: "pulse 1.5s infinite" }}
          ></div>
          <div className="table-responsive">
            <table style={styles.table}>
              <thead>
                <tr>
                  {[1, 2, 3].map((header) => (
                    <th key={header} style={styles.tableHeader}>
                      <div
                        style={{
                          ...styles.tableCell,
                          backgroundColor: "transparent",
                          animation: "pulse 1.5s infinite",
                        }}
                      ></div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5, 6].map((row) => (
                  <tr key={row}>
                    {[1, 2, 3].map((cell) => (
                      <td key={cell}>
                        <div
                          style={{
                            ...styles.tableCell,
                            animation: "pulse 1.5s infinite",
                          }}
                        ></div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TariffSkeleton;
