import { Link } from "react-router-dom";

function TeamMember({ id, name, position, image, socialLinks, boardType }) {
  return (
    <div className="col-lg-3 col-md-6 col-sm-12 team-block">
      <div className="team-block-one">
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
              <Link to={`/${boardType}/${id}`}>
                <img src={image} alt={name} />
              </Link>
            </figure>
            <ul className="social-links clearfix">
              {socialLinks.facebook && (
                <li>
                  <a href={socialLinks.facebook}>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              )}
              {socialLinks.twitter && (
                <li>
                  <a href={socialLinks.twitter}>
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
              )}
              {socialLinks.linkedin && (
                <li>
                  <a href={socialLinks.linkedin}>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="lower-content">
            <h3>
              <Link to={`/${boardType}/${id}`}>{name}</Link>
            </h3>
            <span className="designation">{position}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamMember;
