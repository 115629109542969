import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    getFaqsSuccess: (state, action) => {
      state.items = action.payload;
      state.status = "succeeded";
      state.error = null;
    },
    getFaqsError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchFaqsStart: (state) => {
      state.status = "loading";
      state.error = null;
    },
  },
});

export const { getFaqsSuccess, getFaqsError, fetchFaqsStart } =
  faqsSlice.actions;
export default faqsSlice.reducer;
