import PageTitle from "../components/custom/PageTitle";

function BusinessPrinciples() {
  return (
    <div className="box_wrapper ltr">
      <PageTitle
        title="Business Principles"
        breadcrumbs={[
          { text: "Home", link: "/" },
          { text: "Business Principles" },
        ]}
      />
      <div className="auto-container">
        <div className="business-principles">
          <img
            src="assets/images/service/CorporateValue.jpg"
            alt="Urban street scene with AccessBank building"
            className="header-image"
          />

          <p>
            As a member bank of the AccessGroup network, ABL shares a range of
            core-standardized products (with local adaptations as required) and
            the following principles:
          </p>
          <ul>
            <li>
              <strong>Ease and speed of access</strong> – providing financial
              services to previously un-or under-served parts of the population,
              with the aim to be faster and more efficient than any comparable
              operator in the country;
            </li>
            <li>
              <strong>Transparency and clarity</strong> – communicating all
              products, services and eligibility criteria with as little room
              for subjective interpretation as possible;
            </li>
            <li>
              <strong>Quality leadership</strong> – setting industry standards
              with respect to customer service and quality, ensuring the highest
              quality loan portfolios possible, through adherence to
              international best practices with respect to their underwriting
              standards and credit risk management;
            </li>
            <li>
              <strong>Training and staff development</strong> – recruiting
              talented and motivated staff on a large scale that do not
              necessarily have relevant work experience and qualifying them to
              assume more responsible roles over time through regular trainings
              and coaching as well as frequent staff exchanges between network
              banks.
            </li>
            <li>
              <strong>Strong business ethics</strong> – adhering to a joint set
              of principles of good corporate behavior, covering responsible
              lending practices, adherence to social and environmental
              standards, both international and local, as well as a stringent
              code of conduct for all member-bank employees.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BusinessPrinciples;
