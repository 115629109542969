import axios from "axios";
import {
  createContactSuccess,
  contactError,
  resetContactState,
} from "../slices/contactSlice";

const API_URL = process.env.REACT_APP_API_URL;

export const submitContactForm = (formData) => async (dispatch) => {
  try {
    // Client-side validation
    if (formData.subject.length > 100) {
      throw new Error("Subject cannot be more than 100 characters");
    }
    if (formData.message.length > 500) {
      throw new Error("Message cannot be more than 500 characters");
    }

    const res = await axios.post(`${API_URL}/contacts`, formData);
    if (res.status === 201) {
      dispatch(createContactSuccess(res.data.data));
      dispatch(resetContactState());
      return { success: true, message: res.data.message };
    } else {
      throw new Error(res.data.error || "Failed to submit contact form");
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.error || err.message || "An error occurred";
    dispatch(contactError(errorMessage));
    return { success: false, message: errorMessage };
  }
};
