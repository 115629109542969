const BankingSkeletonLoader = () => {
  const styles = {
    container: {
      maxWidth: "1140px",
      margin: "0 auto",
      padding: "40px 15px",
    },
    title: {
      height: "40px",
      width: "60%",
      backgroundColor: "#e0e0e0",
      margin: "50px auto 30px",
      borderRadius: "4px",
    },
    tabContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px",
    },
    tab: {
      height: "40px",
      width: "200px",
      backgroundColor: "#e0e0e0",
      margin: "0 5px",
      borderRadius: "4px",
    },
    contentContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "50px",
      marginBottom: "50px",
    },
    textContent: {
      width: "55%",
    },
    imageContent: {
      width: "40%",
    },
    text: {
      height: "20px",
      backgroundColor: "#e0e0e0",
      marginBottom: "10px",
      borderRadius: "4px",
    },
    image: {
      height: "360px",
      backgroundColor: "#e0e0e0",
      borderRadius: "4px",
    },
  };

  const pulseAnimation = `
    @keyframes pulse {
      0% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.6;
      }
    }
  `;

  return (
    <div style={styles.container}>
      <style>{pulseAnimation}</style>
      <div style={{ ...styles.title, animation: "pulse 1.5s infinite" }}></div>
      <div style={styles.tabContainer}>
        {[1, 2, 3, 4].map((_, index) => (
          <div
            key={index}
            style={{ ...styles.tab, animation: "pulse 1.5s infinite" }}
          ></div>
        ))}
      </div>
      <div style={styles.contentContainer}>
        <div style={styles.textContent}>
          <div
            style={{
              ...styles.text,
              width: "90%",
              height: "30px",
              marginBottom: "20px",
              animation: "pulse 1.5s infinite",
            }}
          ></div>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <div
              key={index}
              style={{
                ...styles.text,
                width: `${95 - index * 5}%`,
                animation: "pulse 1.5s infinite",
              }}
            ></div>
          ))}
          <div
            style={{
              ...styles.text,
              width: "40%",
              marginTop: "20px",
              animation: "pulse 1.5s infinite",
            }}
          ></div>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <div
              key={index}
              style={{
                ...styles.text,
                width: "90%",
                height: "15px",
                animation: "pulse 1.5s infinite",
              }}
            ></div>
          ))}
        </div>
        <div style={styles.imageContent}>
          <div
            style={{ ...styles.image, animation: "pulse 1.5s infinite" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BankingSkeletonLoader;
