import axios from "axios";
import {
  fetchFaqsStart,
  getFaqsSuccess,
  getFaqsError,
} from "../slices/faqsSlice";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const fetchFaqs = () => async (dispatch) => {
  dispatch(fetchFaqsStart());
  try {
    const res = await axios.get(`${API_URL}/faqs`, {
      headers: getAuthHeader(),
    });
    dispatch(getFaqsSuccess(res.data.data));
  } catch (err) {
    console.log("Get FAQs Error:", err.response);
    dispatch(getFaqsError(err.response?.data?.error || "An error occurred"));
  }
};
