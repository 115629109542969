import { Link } from "react-router-dom";

function BannerItem({ banner }) {
  if (!banner) {
    return (
      <div>
        <h1>No Banner Available</h1>
      </div>
    );
  }

  const { title, subtitle, bannerImage } = banner;

  return (
    <div className="slide-item p_relative">
      <div
        className="bg-layer"
        style={{
          backgroundImage: `url(${bannerImage})`,
        }}
      ></div>
      <div className="pattern-layer">
        <div
          className="pattern-1"
          style={{
            backgroundImage: "url(assets/images/shape/shape-1.png)",
          }}
        ></div>
        <div
          className="pattern-2"
          style={{
            backgroundImage: "url(assets/images/shape/shape-2.png)",
          }}
        ></div>
      </div>
      <div className="auto-container">
        <div className="content-box">
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <p>{subtitle}</p>
          <div className="btn-box">
            <Link to="/about-us" className="theme-btn btn-one">
              Discover More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BannerItem;
