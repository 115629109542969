import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    getTeamMembersSuccess: (state, action) => {
      state.items = action.payload;
      state.status = "succeeded";
    },
    getTeamMembersError: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchTeamMembersStart: (state) => {
      state.status = "loading";
    },
  },
});

export const {
  getTeamMembersSuccess,
  getTeamMembersError,
  fetchTeamMembersStart,
} = teamSlice.actions;
export default teamSlice.reducer;
