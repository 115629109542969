const ServicesSkeleton = () => {
  // Define the styles
  const styles = {
    skeletonText: {
      height: "1rem",
      backgroundColor: "#e0e0e0",
      marginBottom: "0.5rem",
      borderRadius: "0.25rem",
      animation: "skeleton-loading 1s linear infinite alternate",
    },
    skeletonImage: {
      height: "200px",
      backgroundColor: "#e0e0e0",
      borderRadius: "0.5rem",
      animation: "skeleton-loading 1s linear infinite alternate",
    },
    skeletonButton: {
      width: "100px",
      height: "40px",
      backgroundColor: "#e0e0e0",
      borderRadius: "0.25rem",
      animation: "skeleton-loading 1s linear infinite alternate",
    },
  };

  // Define the keyframes animation
  const keyframes = `
    @keyframes skeleton-loading {
      0% {
        background-color: #e0e0e0;
      }
      100% {
        background-color: #f0f0f0;
      }
    }
  `;

  return (
    <>
      <style>{keyframes}</style>
      <section className="service-section pt_120 pb_90">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <div
              style={{
                ...styles.skeletonText,
                width: "25%",
                margin: "0 auto 1rem",
              }}
            ></div>
            <div
              style={{ ...styles.skeletonText, width: "50%", margin: "0 auto" }}
            ></div>
          </div>
          <div className="row clearfix">
            {[...Array(6)].map((_, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 col-sm-12 service-block"
              >
                <div
                  className="service-block-one wow fadeInUp animated"
                  data-wow-delay={`${index * 300}ms`}
                  data-wow-duration="1500ms"
                >
                  <div className="inner-box">
                    <div style={styles.skeletonImage} className="mb-4"></div>
                    <h4
                      style={{ ...styles.skeletonText, width: "75%" }}
                      className="mb-3"
                    ></h4>
                    <p style={styles.skeletonText}></p>
                    <p style={styles.skeletonText}></p>
                    <p style={{ ...styles.skeletonText, width: "75%" }}></p>
                    <div style={styles.skeletonButton} className="mt-4"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesSkeleton;
