import { Link } from "react-router-dom";

function AboutSection() {
  return (
    <section className="about-section pt_120 pb_120">
      <div className="pattern-layer rotate-me"></div>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image_block_one">
              <div className="image-box pr_90 mr_40">
                <div
                  className="image-shape"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-3.png)",
                  }}
                ></div>
                <figure className="image">
                  <img src="assets/images/about-us.gif" alt="About" />
                </figure>
                <div className="rating-box">
                  <ul className="rating mb_5 clearfix">
                    <li>
                      <i className="icon-9"></i>
                    </li>
                    <li>
                      <i className="icon-9"></i>
                    </li>
                    <li>
                      <i className="icon-9"></i>
                    </li>
                    <li>
                      <i className="icon-9"></i>
                    </li>
                    <li>
                      <i className="icon-9"></i>
                    </li>
                  </ul>
                  <h6>5 Star Rating Bank</h6>
                </div>
                <div className="experience-box">
                  <div className="inner">
                    <h2>15</h2>
                    <h6>Years in Liberia</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content_block_one">
              <div className="content-box ml_40">
                <div className="sec-title mb_20">
                  <h6>About US</h6>
                  <h3>Your Trusted Financial Partner in Liberia</h3>
                </div>
                <div className="text-box mb_40">
                  <p>
                    We are dedicated to delivering personalized financial
                    solutions for both individuals and businesses. With a focus
                    on customer satisfaction, we provide a variety of
                    products—from personal savings accounts to business
                    loans—designed to support financial growth at every stage.
                  </p>
                </div>
                <div className="inner-box mb_45">
                  <div className="single-item">
                    <div className="icon-box">
                      <i className="icon-11"></i>
                    </div>
                    <h3>Our Core Values</h3>
                    <p>
                      At the heart of our operations are the following core
                      values:
                    </p>
                    <ol className="core-values-list two-column pt-4 pb-2">
                      <li>
                        <span className="value-title">Ethics</span>
                      </li>
                      <li>
                        <span className="value-title">
                          Employee Development
                        </span>
                      </li>
                      <li>
                        <span className="value-title">Communication</span>
                      </li>
                      <li>
                        <span className="value-title">Professionalism</span>
                      </li>
                      <li>
                        <span className="value-title">Customer Obsession</span>
                      </li>
                      <li>
                        <span className="value-title">Transparency</span>
                      </li>
                      <li>
                        <span className="value-title">Results-Oriented</span>
                      </li>
                    </ol>
                  </div>
                  <div className="single-item">
                    <div className="icon-box">
                      <i className="icon-12"></i>
                    </div>
                    <h3>Comprehensive Services</h3>
                    <p>
                      We offer a wide range of products and services, from
                      business loans to savings accounts, tailored to meet the
                      financial needs of both individuals and businesses.
                    </p>
                  </div>
                </div>
                <div className="btn-box">
                  <Link to="/about-us" className="theme-btn btn-one">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutSection;
