import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBankingServices } from "../redux/actions/bankingServices";
import $ from "jquery";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";

function Navigation() {
  const [isSticky, setIsSticky] = useState(false);
  const [currentSection, setCurrentSection] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    items: bankingServices,
    status,
    error,
  } = useSelector((state) => state.bankingServices);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchBankingServices());
    }
  }, [dispatch, status]);

  useEffect(() => {
    // Update Header Style
    function headerStyle() {
      if (window.scrollY >= 110) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }

    // Initial call
    headerStyle();

    // Add scroll event listener
    window.addEventListener("scroll", headerStyle);

    // Handle hash change for section tracking
    const handleHashChange = () => {
      setCurrentSection(window.location.hash.slice(1));
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Initial call

    // Submenu Dropdown Toggle
    if ($(".main-header li.dropdown ul").length) {
      $(".main-header .navigation li.dropdown").append(
        '<div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>'
      );
    }

    // Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      // Only append menu content if it hasn't been appended already
      if ($(".mobile-menu .menu-box .menu-outer").children().length === 0) {
        var mobileMenuContent = $(".main-header .menu-area .main-menu").html();
        $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);
      }

      // Dropdown Button
      $(".mobile-menu li.dropdown .dropdown-btn").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).toggleClass("open");
        $(this).prev("ul").slideToggle(500);
      });

      // Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      // Menu Toggle Btn
      $(".mobile-menu .menu-backdrop,.mobile-menu .close-btn").on(
        "click",
        function () {
          $("body").removeClass("mobile-menu-visible");
        }
      );
    }

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", headerStyle);
      window.removeEventListener("hashchange", handleHashChange);
      // Remove any event listeners added with jQuery
      $(".mobile-menu li.dropdown .dropdown-btn").off("click");
      $(".mobile-nav-toggler").off("click");
      $(".mobile-menu .menu-backdrop,.mobile-menu .close-btn").off("click");
    };
  }, []);

  const isActive = (path) => {
    if (path === "/banking") {
      return (
        location.pathname.includes("/personal-banking") ||
        location.pathname.includes("/business-banking") ||
        location.pathname.includes("/sme-banking")
      );
    }
    if (
      path.startsWith("/personal-banking") ||
      path.startsWith("/business-banking") ||
      path.startsWith("/sme-banking")
    ) {
      return location.pathname === path && currentSection !== "";
    }
    return location.pathname === path;
  };

  const handleSectionClick = (bankingType, sectionId) => {
    navigate(`/${bankingType}#${sectionId}`);
  };

  const renderBankingSubmenu = (bankingType) => {
    if (status === "loading") {
      return <li>Loading...</li>;
    }

    if (status === "failed") {
      return <li>Error: {error.message}</li>;
    }

    if (status === "succeeded") {
      const approvedServices = bankingServices.filter(
        (service) =>
          service.bankingType.toLowerCase() === bankingType.toLowerCase() &&
          service.status === "approve"
      );

      if (approvedServices.length === 0) {
        return (
          <li>No approved services available for {bankingType} banking</li>
        );
      }

      return approvedServices.map((service) => (
        <li
          key={service._id}
          className={currentSection === service._id ? "current" : ""}
        >
          <Link
            to={`/${bankingType.toLowerCase()}-banking#${service._id}`}
            onClick={() =>
              handleSectionClick(
                `${bankingType.toLowerCase()}-banking`,
                service._id
              )
            }
          >
            {service.tabs[0]?.content?.title || "Untitled Service"}
          </Link>
        </li>
      ));
    }

    return null;
  };

  return (
    <>
      <header className={`main-header${isSticky ? " fixed-header" : ""}`}>
        <div className="header-top">
          <div className="large-container">
            <div className="top-inner">
              <ul className="links-list clearfix">
                <li>
                  <a
                    href="https://hr.accessbank.com.lr/careers/recruitment/vacancies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Career
                  </a>
                </li>

                <li>
                  <NavLink to="/faqs">Faqs</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/blog">Media</NavLink>
                </li> */}
                {/* <li>
                  <Link to="/">Rewards</Link>
                </li> */}
              </ul>
              <ul className="info-list clearfix">
                <li>
                  <i className="icon-1"></i>
                  <a href="mailto:info@accessbankliberia.com">
                    info@accessbankliberia.com
                  </a>
                </li>
                <li>
                  <i className="icon-2"></i>
                  Find Nearest Branch
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="header-lower">
          <div className="large-container">
            <div className="outer-box">
              <div className="logo-box">
                <div className="shape"></div>
                <figure className="logo">
                  <Link to="/">
                    <img src="assets/images/ablLogo2.png" alt="" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area">
                <div className="mobile-nav-toggler">
                  <i className="icon-bar"></i>
                  <i className="icon-bar"></i>
                  <i className="icon-bar"></i>
                </div>
                <nav className="main-menu navbar-expand-md navbar-light clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className={isActive("/") ? "current" : ""}>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li
                        className={`dropdown ${
                          isActive("/about-us") ? "current" : ""
                        }`}
                      >
                        <NavLink to="/about-us">About Us</NavLink>
                        <ul>
                          <li>
                            <Link to="/corporate-value">Corporate Value</Link>
                          </li>
                          <li>
                            <Link to="/share-holders">Shareholders</Link>
                          </li>
                          <li>
                            <Link to="/board-of-directors">
                              Board of Directors
                            </Link>
                          </li>
                          <li>
                            <Link to="/management-board">Management Board</Link>
                          </li>
                          <li>
                            <Link to="/business-principles">
                              Business Principles
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/annual-report">Annual Report</Link>
                          </li> */}
                          {/* <li>
                            <Link to="#">Press Releases</Link>
                          </li> */}
                        </ul>
                      </li>

                      <li
                        className={`dropdown ${
                          isActive("/banking") ? "current" : ""
                        }`}
                      >
                        <NavLink to="#">Banking</NavLink>
                        <ul>
                          <li
                            className={`dropdown ${
                              isActive("/personal-banking") ? "current" : ""
                            }`}
                          >
                            <NavLink to="/personal-banking">
                              Personal Banking
                            </NavLink>
                            <ul>{renderBankingSubmenu("Personal")}</ul>
                          </li>
                          <li
                            className={`dropdown ${
                              isActive("/business-banking") ? "current" : ""
                            }`}
                          >
                            <NavLink to="/business-banking">
                              Business Banking
                            </NavLink>
                            <ul>{renderBankingSubmenu("Business")}</ul>
                          </li>
                          <li
                            className={`dropdown ${
                              isActive("/sme-banking") ? "current" : ""
                            }`}
                          >
                            <NavLink to="/sme-banking">SME Banking</NavLink>
                            <ul>{renderBankingSubmenu("SME")}</ul>
                          </li>
                        </ul>
                      </li>
                      <li className={isActive("/tariff") ? "current" : ""}>
                        <NavLink to="/tariff">Tariff</NavLink>
                      </li>
                      {/* <li className={isActive("/contact-us") ? "current" : ""}>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li> */}
                    </ul>
                  </div>
                </nav>
                <div className="menu-right-content ml_70">
                  <Link to="/annual-report" className="theme-btn btn-two mr_20">
                    Annual Report
                  </Link>
                  <Link to="/contact-us" className="theme-btn btn-one">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isSticky && (
          <div className="sticky-header">
            <div className="large-container">
              <div className="outer-box">
                <div className="logo-box">
                  <div className="shape"></div>
                  <figure className="logo">
                    <Link to="/">
                      <img src="assets/images/ablLogo2.png" alt="" />
                    </Link>
                  </figure>
                </div>
                <div className="menu-area">
                  <nav className="main-menu navbar-expand-md navbar-light clearfix">
                    <div
                      className="collapse navbar-collapse show clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix">
                        <li className={isActive("/") ? "current" : ""}>
                          <NavLink to="/">Home</NavLink>
                        </li>
                        <li
                          className={`dropdown ${
                            isActive("/about-us") ? "current" : ""
                          }`}
                        >
                          <NavLink to="/about-us">About Us</NavLink>
                          <ul>
                            <li>
                              <Link to="/corporate-value">Corporate Value</Link>
                            </li>
                            <li>
                              <Link to="/share-holders">Shareholders</Link>
                            </li>
                            <li>
                              <Link to="/board-of-directors">
                                Board of Directors
                              </Link>
                            </li>
                            <li>
                              <Link to="/management-board">
                                Management Board
                              </Link>
                            </li>
                            <li>
                              <Link to="/business-principles">
                                Business Principles
                              </Link>
                            </li>
                            {/* <li>
                              <Link to="/annual-report">Annual Report</Link>
                            </li> */}
                            {/* <li>
                            <Link to="#">Press Releases</Link>
                          </li> */}
                          </ul>
                        </li>

                        <li
                          className={`dropdown ${
                            isActive("/banking") ? "current" : ""
                          }`}
                        >
                          <NavLink to="#">Banking</NavLink>
                          <ul>
                            <li
                              className={`dropdown ${
                                isActive("/personal-banking") ? "current" : ""
                              }`}
                            >
                              <NavLink to="/personal-banking">
                                Personal Banking
                              </NavLink>
                              <ul>{renderBankingSubmenu("Personal")}</ul>
                            </li>
                            <li
                              className={`dropdown ${
                                isActive("/business-banking") ? "current" : ""
                              }`}
                            >
                              <NavLink to="/business-banking">
                                Business Banking
                              </NavLink>
                              <ul>{renderBankingSubmenu("Business")}</ul>
                            </li>
                            <li
                              className={`dropdown ${
                                isActive("/sme-banking") ? "current" : ""
                              }`}
                            >
                              <NavLink to="/sme-banking">SME Banking</NavLink>
                              <ul>{renderBankingSubmenu("SME")}</ul>
                            </li>
                          </ul>
                        </li>

                        <li className={isActive("/tariff") ? "current" : ""}>
                          <NavLink to="/tariff">Tariff</NavLink>
                        </li>
                        {/* <li
                          className={isActive("/contact-us") ? "current" : ""}
                        >
                          <NavLink to="/contact-us">Contact Us</NavLink>
                        </li> */}
                      </ul>
                    </div>
                  </nav>
                  <div className="menu-right-content ml_70">
                    <Link
                      to="/annual-report"
                      className="theme-btn btn-two mr_20"
                    >
                      Annual Report
                    </Link>
                    <Link to="/contact-us" className="theme-btn btn-one">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>

      <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn">
          <i className="fas fa-times"></i>
        </div>

        <nav className="menu-box">
          <div className="nav-logo">
            <Link to="/">
              <img src="assets/images/ablLogo.png" alt="" title="" />
            </Link>
          </div>
          <div className="menu-outer">
            {/* Here Menu Will Come Automatically Via Javascript / Same Menu as in Header */}
          </div>
          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>Chicago 12, Melborne City, USA</li>
              <li>
                <a href="tel:+8801682648101">+88 01682648101</a>
              </li>
              <li>
                <a href="mailto:info@accessbank.com">
                  info@accessbankliberia.com
                </a>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <a href="index.html">
                  <span className="fab fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="index.html">
                  <span className="fab fa-facebook-square"></span>
                </a>
              </li>
              <li>
                <a href="index.html">
                  <span className="fab fa-pinterest-p"></span>
                </a>
              </li>
              <li>
                <a href="index.html">
                  <span className="fab fa-instagram"></span>
                </a>
              </li>
              <li>
                <a href="index.html">
                  <span className="fab fa-youtube"></span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Navigation;
