import { useEffect, useState } from "react";

function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const viewportHeight = window.innerHeight;
      const bodyHeight = document.body.scrollHeight;

      let percentage = (scrolled / (bodyHeight - viewportHeight)) * 100;
      percentage = percentage > 100 ? 100 : percentage;
      setScrollPercentage(percentage);

      setIsVisible(scrolled > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="scroll-to-top">
      <div>
        <div
          className={`scroll-top-inner ${isVisible ? "visible" : ""}`}
          onClick={scrollToTop}
        >
          <div className="scroll-bar">
            <div
              className="bar-inner"
              style={{ width: `${scrollPercentage}%` }}
            ></div>
          </div>
          <div className="scroll-bar-text">Go To Top</div>
        </div>
      </div>
    </div>
  );
}

export default BackToTop;
