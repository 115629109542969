import { configureStore } from "@reduxjs/toolkit";
import bannersReducer from "./slices/bannersSlice";
import testimonialsReducer from "./slices/testimonialsSlice";
import blogsReducer from "./slices/blogsSlice";
import commentsReducer from "./slices/commentsSlice";
import teamsReducer from "./slices/teamsSlice";
import tariffsReducer from "./slices/tariffsSlice";
import bankingServicesReducer from "./slices/bankingServicesSlice";
import jobsReducer from "./slices/jobsSlice";
import reportsReducer from "./slices/reportsSlice";
import contactReducer from "./slices/contactSlice";
import branchFinderReducer from "./slices/branchFinderSlice";
import faqsReducer from "./slices/faqsSlice";

const store = configureStore({
  reducer: {
    banners: bannersReducer,
    testimonials: testimonialsReducer,
    blogs: blogsReducer,
    comments: commentsReducer,
    teams: teamsReducer,
    tariffs: tariffsReducer,
    bankingServices: bankingServicesReducer,
    jobs: jobsReducer,
    reports: reportsReducer,
    contact: contactReducer,
    branchFinder: branchFinderReducer,
    faqs: faqsReducer,
  },
});

export default store;
