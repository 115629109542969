import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBlogs } from "../redux/actions/blogs";
import { Link } from "react-router-dom";
import PageTitle from "../components/custom/PageTitle";
import UsePagination from "../components/custom/UsePagination";
import NewsBlock from "../components/custom/NewsBlock";
import BlogSkeletonLoader from "../components/custom/skeletonLoader/BlogSkeletonLoader";

function Blog() {
  const dispatch = useDispatch();
  const { items: blogs, status, error } = useSelector((state) => state.blogs);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchBlogs());
    }
  }, [status, dispatch]);

  const approvedBlogs = blogs
    .filter((blog) => blog.status === "approve")
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const postsPerPage = 9;
  const {
    currentItems: currentPosts,
    totalPages,
    currentPage,
    paginate,
    nextPage,
    prevPage,
  } = UsePagination(approvedBlogs, postsPerPage);

  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Blog"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Blog" }]}
      />

      <section className="news-style-two blog-grid pt_120 pb_120">
        <div className="auto-container">
          <div className="row clearfix">
            {status === "loading" ? (
              <BlogSkeletonLoader />
            ) : status === "succeeded" ? (
              currentPosts.length > 0 ? (
                currentPosts.map((post) => (
                  <div
                    key={post._id}
                    className="col-lg-4 col-md-6 col-sm-12 news-block"
                  >
                    <NewsBlock post={post} />
                  </div>
                ))
              ) : (
                <div>
                  <h1>No approved blogs available</h1>
                </div>
              )
            ) : status === "failed" && error ? (
              <div className="w-full h-1/3 mt-5 mb-5">
                <div className="bg-white py-10 dark:bg-dark">
                  <div className="container">
                    <div className="flex w-full rounded-lg border-l-[6px] border-[#F55F58] bg-[#FFF1F0] p-4 dark:bg-[#575558] md:p-[30px]">
                      <div className="mr-5 text-[#F55F58]">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          className="fill-current"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.9999 2.83366C9.57774 2.83366 3.83325 8.57815 3.83325 16.0003C3.83325 23.4225 9.57774 29.167 16.9999 29.167C24.4221 29.167 30.1666 23.4225 30.1666 16.0003C30.1666 8.57815 24.4221 2.83366 16.9999 2.83366ZM0.333252 16.0003C0.333252 7.54021 7.53979 0.33366 15.9999 0.33366C24.46 0.33366 31.6666 7.54021 31.6666 16.0003C31.6666 24.4604 24.46 31.667 15.9999 31.667C7.53979 31.667 0.333252 24.4604 0.333252 16.0003ZM17.8333 8.83366C17.8333 8.18138 17.3182 7.66699 16.6666 7.66699C16.0143 7.66699 15.4999 8.18138 15.4999 8.83366V18.0846C15.4999 18.7369 16.0143 19.2513 16.6666 19.2513C17.3182 19.2513 17.8333 18.7369 17.8333 18.0846V8.83366ZM16.6666 24.5003C17.3182 24.5003 17.8333 23.9859 17.8333 23.3337C17.8333 22.6814 17.3182 22.167 16.6666 22.167C16.0143 22.167 15.4999 22.6814 15.4999 23.3337C15.4999 23.9859 16.0143 24.5003 16.6666 24.5003Z"
                          ></path>
                        </svg>
                      </div>
                      <div className="w-full mb-5">
                        <div className="mb-2 flex items-center justify-between">
                          <h5 className="text-lg font-medium leading-tight text-[#CD3D3A]">
                            {error.message}
                          </h5>
                        </div>
                        <p className="text-base leading-relaxed text-body-color">
                          Please try again later.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {approvedBlogs.length > postsPerPage && (
            <div className="pagination-wrapper centred pt_50">
              <ul className="pagination">
                <li>
                  <Link
                    to="#"
                    onClick={prevPage}
                    className={currentPage === 1 ? "disabled" : ""}
                  >
                    <i className="icon-40"></i>
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, i) => (
                  <li key={i}>
                    <Link
                      to="#"
                      onClick={() => paginate(i + 1)}
                      className={currentPage === i + 1 ? "current" : ""}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    to="#"
                    onClick={nextPage}
                    className={currentPage === totalPages ? "disabled" : ""}
                  >
                    <i className="icon-41"></i>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Blog;
